import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Welcome from './Welcome';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/:user" element={<App />} />

      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes