import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import { ZoomMtg } from '@zoomus/websdk';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.15.2/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {

  let { user } = useParams();
  const [meetingNumber, setMn] = useState(null);
  const [passWord, setPw] = useState(null);

  useEffect(() => {
    let newMn, newPw;
    switch (user) {
      case 'jordan':
        newMn = '4383777522';
        newPw = '';
        break;
      case 'brent':
          newMn = '4584944585';
          newPw = '';
          break;
      case 'shrey':
        newMn = '9081197692';
        newPw = '';
        break;
      case 'summer':
        newMn = '7057829392';
        newPw = '134767';
        break;
    }
    setMn(newMn);
    setPw(newPw);
  }, [])

  var authEndpoint = 'https://us-central1-sliceline-admin-stage.cloudfunctions.net/getMeetingSignature'
  var sdkKey = 't4eteODGQ5iC0rLxK_qTFw'
  var role = 0
  var userName = 'StrideQ Customer'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = 'https://demo.strideq.com'

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <br/>
        <img src='/favicon.ico'/>
        <h1>StrideQ invites you to grow your revenue and save labor cost</h1>

        <button onClick={getSignature}>Learn More</button>
      </main>
    </div>
  );
}

export default App;
