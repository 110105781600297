import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';

function Welcome() {


  return (
    <div className="App">
      <main>
        <br/>
        <img src='/favicon.ico'/>
        <h1>StrideQ Demo</h1>

      </main>
    </div>
  );
}

export default Welcome;
